/* eslint-disable func-names */
<template>
  <b-autocomplete
    v-model="selected"
    :data="data"
    placeholder="Select ..."
    field="name"
    :open-on-focus="true"
    :expanded="true"
    :loading="isFetching"
    :check-infinite-scroll="true"
    @typing="getAsyncData"
    @select="onSelect"
    @infinite-scroll="getMoreAsyncData"
  >
  </b-autocomplete>
</template>
<script>
import debounce from 'lodash/debounce';

export default {
  props: ['id'],
  model: {
    prop: 'id',
    event: 'update',
  },
  data() {
    return {
      data: [],
      isFetching: false,
      name: null,
      offset: 0,
      total: 0,
      limit: 20,
      selected: null,
    };
  },
  mounted() {
    this.getAsyncData('');
  },
  methods: {
    getAsyncData: debounce(function(name) {

      if (this.name !== name) {
        this.name = name;
        this.data = [];
        this.offset = 0;
        this.total = 0;
      }

      if (this.offset > this.total) {
        return;
      }
      this.isFetching = true;
      const params = {
        name,
        offset: this.offset,
        limit: this.limit,
        sort_field: 'name',
        fields: 'name',
      };

      if (this.id && name === '') {
        params.id = this.id;
      }

      this.$http.get('/v1/billing/contracts', { params })
        .then((res) => {
          res.data.forEach((item) => {
            this.data.push(item);
            if (item.id === params.id) {
              this.selected = item.name;
            }
          });
          this.total = parseInt(res.headers['x-total'], 10);
          this.offset += this.limit;
        })
        .catch((error) => {
          this.data = [];
          throw error;
        })
        .finally(() => {
          this.isFetching = false;
        });
    }, 500),
    getMoreAsyncData: debounce(function() {
      this.getAsyncData(this.name);
    }, 250),
    onSelect(option) {
      this.$emit('update', option && option.id);
    },
  },
  watch: {
    id (value) {
      if (value === null) {
        this.selected = null;
        this.getAsyncData('');
      }
    },
  },
};
</script>
