<template>
  <div>
    <form v-on:submit.prevent="save" class="column max">
      <b-field label="ID" horizontal v-if="organization.id">
        {{ organization.id }}
      </b-field>

      <b-field label="Created at" v-if="organization.created_at" horizontal>
        {{ organization.created_at | moment('YYYY.MM.DD H:mm:ss') }}
      </b-field>

      <b-field label="Updated at" v-if="organization.updated_at" horizontal>
        {{ organization.updated_at | moment('YYYY.MM.DD H:mm:ss') }}
      </b-field>

      <b-field label="Name" horizontal>
        <b-input v-if="isEdit && isAdmin" v-model="organization.name" required type="text"></b-input>
        <template v-else>{{ organization.name }}</template>
      </b-field>

      <b-field label="Enabled" horizontal>
        <b-checkbox v-if="isEdit && isAdmin" v-model="organization.enabled">
          {{ organization.enabled ? 'Yes' : 'No' }}
        </b-checkbox>
        <span v-else>{{ organization.enabled ? 'Yes' : 'No' }}</span>
      </b-field>

      <b-field label="API token" horizontal class="token">
        <ApiToken :value="organization.api_token"></ApiToken>
      </b-field>

      <b-field label="Note" horizontal>
        <b-input v-if="isEdit" type="textarea" v-model="organization.note" rows="2"></b-input>
        <vue-simple-markdown v-else :source="organization.note"></vue-simple-markdown>
      </b-field>

      <template v-if="isAdmin">
        <b-field label="Partner" horizontal>
          <b-input v-if="isEdit" v-model="organization.partner" type="text"></b-input>
          <span v-else>{{ organization.partner }}</span>
        </b-field>

        <b-field label="Owner" horizontal>
          <b-input v-if="isEdit" v-model="organization.owner" type="text"></b-input>
          <span v-else>{{ organization.owner }}</span>
        </b-field>

        <b-field label="Internal" horizontal>
          <b-input v-if="isEdit" type="textarea" v-model="organization.internal" rows="2"></b-input>
          <vue-simple-markdown v-else :source="organization.internal"></vue-simple-markdown>
        </b-field>

        <b-field v-if="isAdmin" label="Contract" horizontal>
          <ContractsSelect v-if="isEdit" v-model="organization.contract_id"></ContractsSelect>
          <b-button v-if="isEdit" icon-left="close" @click="organization.contract_id = null" title="remove contract">
          </b-button>
          <ContractDetail :id="organization.contract_id" v-else></ContractDetail>
        </b-field>
      </template>

      <b-field label="" horizontal>
        <div class="buttons">
          <b-button v-if="isNew" type="is-success" icon-left="plus-box" native-type="submit">Add Organization</b-button>
          <template v-else-if="!edit">
            <b-button type="is-info" icon-left="pencil" :disabled="isFetching" v-on:click="edit = true"
              >Edit Organization</b-button
            >
            <router-link :to="{ name: 'groups', params: { organizationId: organization.id } }">
              <b-button type="is-info" icon-left="group">Show Groups</b-button>
            </router-link>
          </template>
          <template v-else>
            <b-button type="is-info" icon-left="close" :disabled="isFetching" v-on:click="closeEdit">Close</b-button>
            <b-button type="is-success" icon-left="content-save" native-type="submit" :disabled="isFetching"
              >Save Organization</b-button
            >
            <b-button type="is-link" v-on:click="showRenewTokenDialog" icon-left="refresh" :disabled="isFetching"
              >Renew Organization Token</b-button
            >
            <b-button
              v-if="isAdmin"
              type="is-danger"
              v-on:click="showDeleteDialog"
              icon-left="trash-can"
              :disabled="isFetching"
              >Delete Organization</b-button
            >
          </template>
        </div>
      </b-field>
    </form>

    <div class="modal is-active" v-if="deleteDialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete confirmation</p>
          <button class="delete" aria-label="close" v-on:click="closeDeleteDialog"></button>
        </header>
        <section class="modal-card-body">Do you really want to delete this organization?</section>
        <footer class="modal-card-foot">
          <button class="button is-danger" v-on:click="deleteMe">Delete</button>
          <button class="button" v-on:click="closeDeleteDialog">Cancel</button>
        </footer>
      </div>
    </div>

    <div class="modal is-active" v-if="renewTokenDialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Renew token confirmation</p>
          <button class="delete" aria-label="close" v-on:click="closeRenewTokenDialog"></button>
        </header>
        <section class="modal-card-body">Do you really want to renew API token for this organization?</section>
        <footer class="modal-card-foot">
          <button class="button is-danger" v-on:click="renewToken">Renew token</button>
          <button class="button" v-on:click="closeRenewTokenDialog">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import ApiToken from '@/components/ApiToken.vue';
import Organization from '../models/Organization';
import ContractsSelect from '../components/ContractsSelect.vue';
import ContractDetail from '../components/ContractDetail.vue';

export default {
  name: 'organization',
  components: {
    ApiToken,
    ContractsSelect,
    ContractDetail,
  },
  data() {
    return {
      edit: false,
      organization: {
        enabled: true,
      },
      isFetching: false,
      deleteDialog: false,
      renewTokenDialog: false,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.fetch();
    }
  },
  computed: {
    isNew() {
      return this.organization.id === undefined;
    },
    isAdmin() {
      return this.$store.state.userIsAdmin;
    },
    isEdit() {
      return this.edit || this.isNew;
    },
  },
  methods: {
    async fetch() {
      this.isFetching = true;
      const { data } = await Organization.getOne(this.$route.params.id);
      this.organization = data;
      this.isFetching = false;
    },
    async save() {
      if (this.organization.id) {
        if (this.isAdmin) {
          const { data } = await Organization.update(this.organization);
          this.organization = data;
        } else {
          const { data } = await this.$http.put(`/v1/organization/${this.organization.id}`, {
            note: this.organization.note,
          });
          this.organization = data;
        }
      } else {
        const { data } = await Organization.add(this.organization);
        this.organization = data;
        this.$router.push({ name: 'organization', params: { id: data.id } });
      }
      this.$toast.success('Organization was saved');
    },
    async deleteMe() {
      if (!this.deleteDialog) return;
      await Organization.delete(this.organization.id);
      this.closeDeleteDialog();
      this.$toast.success('Organization was deleted');
      this.$router.push({ name: 'organizations' });
    },
    showDeleteDialog(e) {
      e.preventDefault();
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.deleteDialog = null;
    },
    showRenewTokenDialog(e) {
      e.preventDefault();
      this.renewTokenDialog = true;
    },
    closeRenewTokenDialog() {
      this.renewTokenDialog = false;
    },
    closeEdit() {
      this.edit = false;
      this.fetch();
    },
    async renewToken() {
      if (!this.renewTokenDialog) return;
      this.organization.api_token = 'renew';
      const { data } = await this.$http.put(`/v1/organization/${this.organization.id}`, { api_token: 'renew' });
      this.organization = data;
      this.closeRenewTokenDialog();
      this.$toast.success('Organization token was renewed');
    },
  },
};
</script>
